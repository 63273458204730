<template>
  <div>
    <gl-title title="小喜管理"></gl-title>
    <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="contacts" label="联系人">
          <gl-list-search v-model="mFormData.contacts" placeholder="请输入联系人"></gl-list-search>
        </el-form-item>
        <el-form-item prop="userName" label="用户昵称">
          <gl-list-search v-model="mFormData.userName" placeholder="请输入用户昵称"></gl-list-search>
        </el-form-item>
        <el-form-item>
          <el-button  type="primary" icon="el-icon-search" @click="mReload"> 搜索 </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" width="44"></el-table-column>
        <el-table-column prop="userId" label="用户ID" width="100"></el-table-column>
        <el-table-column prop="userName" label="用户昵称"></el-table-column>
        <el-table-column prop="contacts" label="联系人"></el-table-column>
        <el-table-column prop="contactsPhone" label="联系人电话"></el-table-column>
        <el-table-column prop="region" label="所属区域"></el-table-column>
        <el-table-column prop="addressDetail" label="具体地址"></el-table-column>
        <el-table-column prop="fans" label="喜粉数量"></el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import {userList} from '@/api/coox/userInfo'
export default {
  name: 'userInfoList',
  mixins: [ListMixin],
  mounted(){
    this.mGetListFun = userList
    // this.mForm.userId = this.$store.state.user.userInfo.userId
    this.mGetList()
  },
}
</script>

<style>

</style>